import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
// import { CollectionQuery } from '../utils/collection/collection.model';
// import useSWR from 'swr';
// import fetcher from '../baseQuery/fetcher';

const Vacancy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const { t } = useTranslation(['components_header_trn', 'navbar_translation']);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isHovered, setIsHovered] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const itemsPerPage = 2;

  const currentDate = new Date().toISOString().split('T')[0];
  console.log('🚀 ~ Vacancy ~ currentDate:', currentDate);

  const [collection, setCollection] = useState({
    locale: currentLanguage,
    top: itemsPerPage,
    skip: itemsPerPage * (currentPage - 1),
  });

  const {
    data: vacancyData,
    loading,
    error,
  } = useAxiosQuery({
    url: `/portal-careers/get-careers`,
    method: 'GET',
    params: collection,
  });

  // pagination

  const totalVacancies = vacancyData?.count || 0;
  useEffect(() => {
    const currentTotalPage = Math.ceil(totalVacancies / itemsPerPage);
    setTotalPage(currentTotalPage);
  }, [currentPage, totalVacancies]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleClick = (value) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter((v) => v !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  const allTags = [
    ...new Set(vacancyData?.data?.flatMap((data) => data?.tags)),
  ];

  console.log('selectedValues', selectedValues);

  const Tags = allTags?.map((tag, index) => (
    <div
      key={index}
      className={`badge btn btn-secondary rounded-pill ${
        selectedValues.includes(tag)
          ? 'bg-primary text-white'
          : 'bg-light text-dark'
      } px-2 py-1`}
      onClick={() => handleClick(tag)}
    >
      {tag}
    </div>
  ));

  //search

  const [searchQuery, setSearchQuery] = useState('');
  const timeoutIdRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      if (searchQuery) {
        setCollection({
          locale: currentLanguage,
          search: searchQuery,
          searchFrom: ['title', 'description'],
        });
      }
    }, 500);
    return () => clearTimeout(timeoutIdRef.current);
  }, [searchQuery]);

  return (
    <>
      {/* <!-- Page Header Start --> */}
      {currentPath === '/vacancy' ? (
        <>
          <Nav />
          <div className='container-fluid page-header py-1'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Vacancy`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>{t(`Home`)}</Link>
                  </li>
                  <li className='breadcrumb-item' aria-current='page'>
                    {t(`Vacancy`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </>
      ) : null}

      <div className='container-fluid vacancy py-2 my-5'>
        <div className='container py-2'>
          {vacancyData?.count === 0 && collection?.skip === 0 ? (
            <div className='text-center'>
              <h3>{t(`No vacancies found`)}</h3>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-3 col-lg-4 wow fadeIn g-5'>
                <div className='text-start'>
                  <div className='vacancy-side-item bg-light'>
                    <div>
                      <h4 className='text-center pt-4'>{t(`Filter Jobs`)}</h4>
                    </div>
                    <div className='mx-6 text-start'>
                      <div className='p-4 text-start'>
                        <div className='d-flex justify-content-end align-items-center pb-2 text-start'>
                          <button
                            className='btn btn-outline-primary btn-block btn-xs mt-2 btn-sm'
                            onClick={() => {
                              setCollection({
                                locale: currentLanguage,
                                top: itemsPerPage,
                                skip: itemsPerPage * (currentPage - 1),
                              });
                              setSearchQuery('');
                              setSelectedValues([]);
                            }}
                          >
                            {t('Clear Filter')}
                          </button>
                        </div>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control w-200 size-12 pe-5'
                            placeholder={t(`Search`)}
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <span className='position-absolute top-50 end-0 translate-middle-y pe-2'>
                            <i className='fas fa-search'></i>{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='d-flex flex-wrap gap-2 justify-content-center mt-md-2'>
                        {Tags}
                      </div>
                      <div className='d-flex justify-content-end  align-items-center p-4 text-start '>
                        <button
                          className='btn btn-primary btn-block mt-2 btn-sm'
                          onClick={() => {
                            setCollection({
                              locale: currentLanguage,
                              filter: [
                                [
                                  {
                                    field: 'tags',
                                    operator: 'ANY',
                                    value: selectedValues?.map(
                                      (selectedValue) => selectedValue
                                    ),
                                  },
                                ],
                              ],
                            });
                          }}
                        >
                          {t(`Filter`)}
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className='mt-3 mx-4 text-start '>
                        <label className='form-label'>
                          {t(`Filter By Term Of Employment`)}
                        </label>
                        <select
                          className='form-select'
                          aria-label='Default select example'
                          onChange={(e) => {
                            setCollection({
                              locale: currentLanguage,
                              filter: [
                                [
                                  {
                                    field: 'termOfEmployment',
                                    operator: '=',
                                    value: e.target.value,
                                  },
                                ],
                              ],
                            });
                          }}
                        >
                          <option value='Permanent'>Permanent</option>
                          <option value='Temporary'>Temporary</option>
                          <option value='Fixed Term'>Fixed Term</option>
                          <option value='Remote'>Remote</option>
                          <option value='Casual'>Casual</option>
                          <option value='Other'>Other</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className='mt-3 mx-4 text-start '>
                        <label className='form-label'>
                          {t(`Filter By Work Schedule`)}
                        </label>
                        <select
                          className='form-select'
                          aria-label='Default select example'
                          onChange={(e) => {
                            setCollection({
                              locale: currentLanguage,
                              filter: [
                                [
                                  {
                                    field: 'workSchedule',
                                    operator: '=',
                                    value: e.target.value,
                                  },
                                ],
                              ],
                            });
                          }}
                        >
                          <option selected value='Full Time'>
                            Full Time
                          </option>
                          <option value='Part Time'>Part Time</option>
                          <option value='Contract'>Contract</option>
                          <option value='Internship'>Internship</option>
                          <option value='Freelance'>Freelance</option>
                          <option value='Apprenticeship'>Apprenticeship</option>
                          <option value='Self Employed'>Self Employed</option>
                          <option value='Volunteer'>Volunteer</option>
                          <option value='Zero Hours Contract'>
                            Zero Hours Contract
                          </option>
                          <option value='Other'>Other</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className='p-4 text-start '></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-lg-8 wow fadeIn g-5'>
                <div className='row g-5 '>
                  {vacancyData?.count === 0 ? (
                    <div className='text-center mt-10'>
                      <h3>{t(`No vacancies found`)}</h3>
                    </div>
                  ) : (
                    <>
                      {error && (
                        <div className='flex justify-center align-center'>
                          <div>
                            <h4 className='text-center mt-20'>
                              {t(`Something went wrong`)}
                            </h4>
                          </div>
                        </div>
                      )}
                      {vacancyData?.data?.map((vacancy) => (
                        <div
                          key={vacancy?.id}
                          className='col-md-12 col-lg-12 wow fadeIn g-5'
                          data-wow-delay='.3s'
                        >
                          <div className='vacancy-item bg-light'>
                            <div className='p-4 text-start '>
                              <div className='bg-light'>
                                <div className='p-4 text-start'>
                                  <div className='text-start'>
                                    <div className='d-flex flex-column flex-md-row justify-content-between mb-3'>
                                      <div>
                                        <h5 className='mt-1 mb-3 txt-primary'>
                                          {vacancy?.title}
                                        </h5>
                                      </div>
                                      <div className='text-nowrap mt-2 mt-md-0'>
                                        {dayjs(vacancy?.endDate).diff(
                                          dayjs(currentDate),
                                          'day'
                                        ) >= 0 && (
                                          <button
                                            type='button'
                                            className={`btn btn-sm ${
                                              dayjs(vacancy?.endDate).diff(
                                                dayjs(currentDate),
                                                'day'
                                              ) >= 10
                                                ? 'btn-success'
                                                : dayjs(vacancy?.endDate).diff(
                                                    dayjs(currentDate),
                                                    'day'
                                                  ) >= 5
                                                ? 'btn-warning'
                                                : 'btn-danger'
                                            }`}
                                            onClick={() =>
                                              navigate(
                                                `/vacancy/${vacancy?.id}`
                                              )
                                            }
                                          >
                                            {dayjs(vacancy?.endDate).diff(
                                              dayjs(currentDate),
                                              'day'
                                            )}{' '}
                                            {t(`Days Left`)}
                                          </button>
                                        )}
                                      </div>
                                    </div>

                                    <div className='d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                      <div className='d-flex align-items-center mb-2'>
                                        <i
                                          className={`bi ${
                                            isHovered
                                              ? 'bi-bookmark-check-fill'
                                              : 'bi-bookmark'
                                          } txt-primary fs-5 me-2`}
                                          onMouseOver={() => setIsHovered(true)}
                                          onMouseOut={() => setIsHovered(false)}
                                          onClick={() =>
                                            navigate(`/vacancy/${vacancy?.id}`)
                                          }
                                        ></i>
                                        {vacancy?.termOfEmployment}
                                      </div>
                                      <div className='d-flex align-items-center mb-2'>
                                        <i className='bi bi-clock txt-primary fs-5 me-2'></i>
                                        {vacancy?.workSchedule}
                                      </div>
                                      <div className='d-flex align-items-center mb-2'>
                                        <i className='bi bi-calendar-event txt-primary fs-5 me-2'></i>
                                        {dayjs(vacancy?.endDate).format(
                                          'DD MMM YYYY'
                                        )}
                                      </div>
                                    </div>

                                    <div className='row g-3'>
                                      <div className='col-12 col-md-4'>
                                        <div className='d-flex align-items-center'>
                                          <i className='bi bi-wallet-fill txt-primary fs-5 me-2'></i>
                                          {vacancy?.salaryRange === '0'
                                            ? 'Negotiable'
                                            : `$${vacancy?.salaryRange}`}
                                        </div>
                                      </div>
                                      <div className='col-12 col-md-8'>
                                        <div className='d-flex align-items-center'>
                                          <i className='bi bi-calendar2-check-fill txt-primary fs-5 me-2'></i>
                                          {dayjs(currentDate).format(
                                            'DD MMM YYYY'
                                          )}{' '}
                                          {' - '}
                                          {dayjs(vacancy?.endDate).format(
                                            'DD MMM YYYY'
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <ul className='list-unstyled mt-4'>
                                      <li className='mb-2'>
                                        <i className='bi bi-check-circle txt-primary fs-5'></i>
                                        <span className='ms-2'>
                                          <strong>{t('Qualification')}:</strong>{' '}
                                          {vacancy?.requirements?.qualification}
                                        </span>
                                      </li>
                                      <li className='mb-2'>
                                        <i className='bi bi-check-circle txt-primary fs-5'></i>
                                        <span className='ms-2'>
                                          <strong>{t('Experience')}:</strong>{' '}
                                          {vacancy?.requirements?.experience}
                                        </span>
                                      </li>
                                    </ul>

                                    <div className='d-flex justify-content-end mt-4'>
                                      <Link
                                        to={`/vacancy/${vacancy.id}`}
                                        className='btn text-capitalize size-md txt-sm d-flex justify-content-center btn-primary text-white rounded-pill'
                                      >
                                        {t(`View Detail`, {
                                          ns: 'components_header_trn',
                                        })}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {vacancyData?.count > 2 && (
                        <div className='pagination'>
                          <button
                            className='page-item'
                            style={{ justifyContent: 'center' }}
                            title={t('Previous Page')}
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            <i className='bi bi-chevron-left'></i>
                          </button>
                          {Array.from({ length: totalPages }, (_, index) => (
                            <button
                              key={index + 1}
                              onClick={() => setCurrentPage(index + 1)}
                              disabled={currentPage === index + 1}
                              className={`page-item ${
                                currentPage === index + 1 ? 'active' : ''
                              }`}
                            >
                              {index + 1}
                            </button>
                          ))}
                          <button
                            className='page-item'
                            style={{ justifyContent: 'center' }}
                            title={t('Next Page')}
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            <i className='bi bi-chevron-right'></i>
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {!showMore && vacancyData?.count > 2 && !loading && (
                    <div>
                      <button
                        type='button'
                        className='btn btn-primary text-white px-5 py-3 rounded-pill'
                        style={{ justifyContent: 'center' }}
                        onClick={() => {
                          setShowMore(true);
                        }}
                      >
                        {t(`See More`, {
                          ns: 'components_header_trn',
                        })}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {currentPath === '/vacancy' ? <Footer /> : null}
    </>
  );
};

export default Vacancy;
